import * as React from "react";
import { useState, useRef } from "react";
import { useLocation } from "@reach/router";
import "./index.css";
import formattedListData from "../../data.json";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import { Link, graphql, useStaticQuery } from "gatsby";

import { slugify } from "../../helpers";

import KeyboardLeftArrow from "./keyboard_arrow_left.svg";
import KeyboardRightArrow from "./keyboard_arrow_right.svg";
import ToTheMaxScreenshot from "./screenshots/tothemax.png";
import TripadvisorScreenshot from "./screenshots/hide-restaurant.png";

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      favouriteListsData {
        lists {
          id
          name
          imageUrl
        }
      }
    }
  `);

  const allListData = data.favouriteListsData.lists;

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const listsPerPage = 6;

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page on category change
    // window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
  };

  const getLists = () => {
    if (selectedCategory === "All") {
      return formattedListData.categories
        .flatMap((category) =>
          category.lists.map((list) => ({
            ...list,
            category: category.name, // assuming category has a 'name' property
          }))
        )
        .filter((list) => list.featuredOrder)
        .sort((a, b) => a.featuredOrder - b.featuredOrder);
    } else {
      return formattedListData.categories
        .find((category) => category.name === selectedCategory)
        .lists.sort((a, b) => a.featuredOrder - b.featuredOrder);
    }
  };

  const getPaginatedLists = () => {
    const allLists = getLists();
    const startIndex = (currentPage - 1) * listsPerPage;
    const endIndex = startIndex + listsPerPage;
    return allLists.slice(startIndex, endIndex);
  };

  const totalLists = getLists().length;
  const totalPages = Math.ceil(totalLists / listsPerPage);
  const paginatedLists = getPaginatedLists();

  const isBrowser = typeof window !== "undefined";

  const categoriesContainerRef = useRef(null);

  const scrollCategories = (direction) => {
    if (categoriesContainerRef.current) {
      const scrollAmount = 150; // Adjust scroll amount as needed
      categoriesContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
  };

  return (
    <main>
      <NavigationBar />
      <div className="hero-section">
        <div>
          <h1 id="website-title">Unleashing Life Through Technology</h1>
          <p id="website-tagline">
            Innovative tools designed to track, enhance, and optimize the most
            meaningful experiences
          </p>
        </div>
      </div>
      <div className="container">
        <p>
          I’m passionate about building tools that allow me to track, enhance,
          and fully experience the best that life has to offer. My focus is on
          creating innovative solutions that help you stay connected to your
          interests — whether it's discovering new places, savoring unique
          experiences, or diving into personal passions.
        </p>
        <p>
          These tools are designed to seamlessly organize my adventures and
          goals, ensuring that every moment counts and no opportunity is
          overlooked. Join me in exploring how technology can unlock a more
          fulfilling, enriched life.
        </p>
      </div>
      <div className="categories-section">
        <div className="container">
          <div className="categories-scroll-wrapper">
            <img
              src={KeyboardLeftArrow}
              alt="Scroll Left"
              className="scroll-arrow left-arrow"
              onClick={() => scrollCategories("left")}
            />
            <div className="categories-list" ref={categoriesContainerRef}>
              {[
                "All",
                ...formattedListData.categories.map(
                  (category) => category.name
                ),
              ].map((category) => (
                <div
                  key={category}
                  className={`category-button ${
                    selectedCategory === category ? "active" : ""
                  }`}
                  onClick={() => handleCategoryChange(category)}
                >
                  {category}
                </div>
              ))}
            </div>
            <img
              src={KeyboardRightArrow}
              alt="Scroll Right"
              className="scroll-arrow right-arrow"
              onClick={() => scrollCategories("right")}
            />
          </div>
          <div className="lists-section">
            <div>
              {isBrowser && (
                <div>
                  {paginatedLists.map((list, index) => {
                    const listData = allListData.find(
                      (listItem) => listItem.id === list.listId
                    );

                    if (!listData) {
                      console.log("listData", list);
                      return <div />;
                    }

                    return (
                      <div key={listData.id} className="list-wrapper">
                        <Link to={slugify(listData.name)} target="_blank">
                          <div className="list-block">
                            {list.category && (
                              <div className="list-category">
                                {list.category}
                              </div>
                            )}
                            <img
                              src={listData.imageUrl}
                              className="list-image"
                            />
                            <div className="list-content">
                              <div className="list-title">{list.name}</div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                  {totalPages > 1 && (
                    <div className="pagination">
                      <div
                        className="page-button"
                        onClick={() =>
                          currentPage > 1 && handlePageChange(currentPage - 1)
                        }
                      >
                        <img src={KeyboardLeftArrow} alt="Previous" />
                      </div>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <div
                          key={i + 1}
                          className={`page-button ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </div>
                      ))}
                      <div
                        className="page-button"
                        onClick={() =>
                          currentPage < totalPages &&
                          handlePageChange(currentPage + 1)
                        }
                      >
                        <img src={KeyboardRightArrow} alt="Next" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="feature-section">
        <div className="feature-content container">
          <img
            src={ToTheMaxScreenshot}
            alt="ToTheMax Screenshot"
            className="feature-image"
          />
          <div className="feature-description">
            <h2>ToTheMax</h2>
            <p>
              ToTheMax is my go-to tool for managing diverse lists of things I
              want to explore and experience. Whether it's tracking tourist
              attractions, restaurants, beers, comedians, or more, ToTheMax
              helps me stay organized and on top of all my interests.
            </p>
            <Link to="/tothemax">
              <div className="read-more-button">Read more</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="feature-section">
        <div className="feature-content container">
          <div className="feature-description">
            <h2>Tripadvisor Restaurant Filter</h2>
            <p>
              The Tripadvisor Restaurant Filter is a Chrome extension I've built
              to enhance your dining experience. It allows users to customize
              their restaurant search results by hiding specific restaurants,
              helping them to focus on the best options available. Whether
              you’re avoiding certain cuisines or looking for top-rated places,
              this tool streamlines the search process on Tripadvisor, making it
              easier to find your next favorite restaurant.
            </p>
            <Link to="/tripadvisor-filter">
              <div className="read-more-button">Read more</div>
            </Link>
          </div>
          <img
            src={TripadvisorScreenshot}
            alt="Tripadvisor Restaurant Filter Screenshot"
            className="feature-image"
          />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;

export const Head = ({ pageContext }) => {
  const location = useLocation();
  return (
    <SEO
      index={true}
      follow={true}
      title={"Unleashing Life Through Technology"}
      pathname={location.pathname}
    />
  );
};
